import HeaderCom from '../../../components/M-header.vue';

export default {
    name: 'audit',
    components: {
        HeaderCom
    },
    data() {
        const validateTime = (rule, value, callback) => {
            if (value.length == 0 || value[0] == '' || value[1] == '') {
                callback(new Error('请选择考试时间'))
            } else {
                callback()
            }
        }
        // 验证考场考生人数
        var examNum = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入考场考生人数"));
            } else if (value > this.planNum) {
                callback(new Error("考场考生人数不能大于待安排考生人数!"));
            } else {
                callback();
            }
        };
        return {
            batchGenExamPlanNo: [],
            batchMode: false,
            total: 0, // 总条目数
            currentPage: 1, // 当前页
            limit: 10, // 每页显示数量
            loading: false,
            hasAllotLoading: false,
            checkLoading: false,
            editType: '',
            queryArray: [],
            formInline: {},
            stateList: [{value: '0', label: '未发布'}, {value: '1', label: '已发布'}],
            subjectList: JSON.parse(sessionStorage.getItem('subjectList')), // 考试科目
            examMajorList:JSON.parse(sessionStorage.getItem('specialList')), // 测试专业
            selections: [], // 表格被选中的内容
            spinShow: false,
            loadText: '准考证生成中...',
            tabColumns: [
                // { type: 'selection', width: 50, align: 'center' },
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '考试计划流水号', key: 'examPlanNo', align: 'center', tooltip: true, resizable: true, minWidth: 150},
                {title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, minWidth: 150},
                {title: '考试开始时间', key: 'examBeginTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '考试结束时间', key: 'examEndTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '考场号', key: 'examRoom', align: 'center', width: 100},
                {title: '考试地点', key: 'examAddress', align: 'center', tooltip: true, resizable: true, minWidth: 120},
                {title: '考场考生人数', slot: 'examPersonCount', align: 'center', width: 120},
                {
                    title: '发布状态', key: 'submit', align: 'center', width: 100,
                    render: (h, params) => {
                        let tmpStr = "";
                        if (params.row.submit == 1) {
                            tmpStr = "已发布";
                        } else {
                            tmpStr = "未发布";
                        }
                        return h('span', {
                            style: {
                                color: params.row.submit == 1 ? "#19be6b" : "#ed3f14"
                            }
                        }, tmpStr)
                    }
                },
                {
                    title: '准考证状态', key: 'submit', align: 'center', width: 140,
                    render: (h, params) => {
                        let tmpStr = "";
                        if (params.row.genExamTicketNo == 1) {
                            tmpStr = "已生成准考证";
                        } else {
                            tmpStr = "未生成准考证";
                        }
                        return h('span', {
                            style: {
                                color: params.row.genExamTicketNo == 1 ? "#19be6b" : "#ed3f14"
                            }
                        }, tmpStr)
                    }
                },
                {title: '计划发布时间', key: 'submitDate', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '操作', slot: 'action', width: 150, align: 'center', fixed: 'right'}
            ],
            tabData: [],
            studentModal: false, // 查看考场考生
            studentColumn: [
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '准考证号', key: 'examTicketNo', align: 'center'},
                {title: '座位号', key: 'seatNo', align: 'center', width: 70},
                {title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, width: 240},
                // { title: '考场号', key: 'examRoom', align: 'center' },
                // { title: '考试地点', key: 'address', align: 'center', tooltip: true },
                // { title: '考试开始时间', key: 'startTime', align: 'center' },
                // { title: '考试结束时间', key: 'endTime', align: 'center' },
                {title: '姓名', key: 'regName', align: 'center'},
                {title: '性别', key: 'gender', align: 'center', width: 70},
                {title: '身份证号', key: 'idNumber', align: 'center'},
                {title: '联系电话', key: 'tel', align: 'center', width: 110},
                {title: '测试级别', key: 'examMajorTitle', align: 'center', width: 120},
                {title: '测试专业', key: 'examMajor', align: 'center', width: 120},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 240}
            ],
            studentData: [],
            autoModal: false, // 自动分配考生
            sureLoading: false, // 确定加载
            // loadings: false,
            planNum: 0, // 待安排考生人数
            autoForm: {
                examValue: [],
                examMajorTitle: '',
                examMajor: '',
                // examBeginTime: this.examValue[0],
                // examEndTime: this.examValue[1],
                examRoom: '',
                examAddress: '',
                examPersonCount: '',
            },
            autoRule: {
                examMajorTitle: [{required: true, message: '考试科目为必填', trigger: 'change'}],
                examValue: [{required: true, validator: validateTime}],
                // examValue: [{ required: true, message: '考试时间为必填', trigger: 'change' }],
                examAddress: [{required: true, message: '考试地点为必填', trigger: 'change'}],
                examRoom: [{required: true, message: '考场号为必填', trigger: 'change'}],
                examPersonCount: [{required: true, validator: examNum, trigger: 'change'}],
            },
            handModal: false, // 手动分配考生
            handSave: true,
            handTitle: '手动分配考生',
            handForm: {
                examValue: [],
                examMajorTitle: '',
                examRoom: '',
                examAddress: '',
                examPersonCount: '',
            },
            handRule: {
                examMajorTitle: [{required: true, message: '考试科目为必填', trigger: 'change'}],
                examValue: [{required: true, validator: validateTime}],
                // examValue: [{ required: true, message: '考试时间为必填', trigger: 'change' }],
                examAddress: [{required: true, message: '考试地点为必填', trigger: 'change'}],
                examRoom: [{required: true, message: '考场号为必填', trigger: 'change'}],
                // examPersonCount: [{ required: true, message: '考场考生人数为必填', trigger: 'change' }],
            },
            condition: '',
            // 待分配考生
            allotColumn: [
                // { type: 'selection', width: 50, align: 'center' },
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '姓名', key: 'name', align: 'center'},
                {title: '性别', key: 'gender', align: 'center', width: 50},
                {title: '身份证号', key: 'idNumber', align: 'center', tooltip: true},
                {title: '联系电话', key: 'tel', align: 'center', tooltip: true},
                {title: '测试级别', key: 'examMajorTitle', align: 'center'},
                {title: '测试专业', key: 'examMajor', align: 'center'},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 120},
                {title: '操作', slot: 'action', width: 100, align: 'center'}
            ],
            allotData: [],
            plan: {
                total: 0, // 总条目数
                currentPage: 1, // 当前页
                limit: 10, // 每页显示数量
            },
            planColumn: [
                {title: '考试计划流水号', key: 'examPlanNo', align: 'center', tooltip: true, resizable: true, minWidth: 150},
                {title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, minWidth: 150},
                {title: '考试开始时间', key: 'examBeginTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '考试结束时间', key: 'examEndTime', align: 'center', tooltip: true, resizable: true, width: 110},
                {title: '考场号', key: 'examRoom', align: 'center', width: 100},
                {title: '考试地点', key: 'examAddress', align: 'center', tooltip: true, resizable: true, minWidth: 120},
                {title: '考场考生人数', key: 'examPersonCount', align: 'center', width: 120},
            ],
            planData: [],
            replaceLoading: false,
            replaceColumn: [
                // { type: 'selection', width: 50, align: 'center' },
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '姓名', key: 'regName', align: 'center'},
                {title: '性别', key: 'gender', align: 'center', width: 50},
                {title: '身份证号', key: 'idNumber', align: 'center', tooltip: true},
                {title: '联系电话', key: 'tel', align: 'center', tooltip: true},
                {title: '测试级别', key: 'examMajorTitle', align: 'center'},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 120},
                {title: '操作', slot: 'action', width: 100, align: 'center'}
            ],
            replaceData: [],
            // 已分配考生
            seatIndex: -1,  // 当前聚焦的输入框的行数
            editSeat: '', // 座位号输入框
            hasAllotColumn: [
                {type: 'selection', width: 50, align: 'center'},
                {title: '序号', type: 'index', width: 50, align: 'center'},
                {title: '姓名', key: 'regName', align: 'center'},
                {title: '性别', key: 'gender', align: 'center', width: 50},
                {title: '座位号', slot: 'seatNo', align: 'center', width: 100},
                {title: '身份证号', key: 'idNumber', align: 'center', tooltip: true},
                {title: '联系电话', key: 'tel', align: 'center', tooltip: true},
                {title: '测试级别', key: 'examMajorTitle', align: 'center'},
                {title: '测试专业', key: 'examMajor', align: 'center'},
                {title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 120},
                {title: '操作', slot: 'action', width: 180, align: 'center'}
            ],
            hasAllotData: [],
            replaceMode: false, // 替换考场考生
            publishTime: {
                shortcuts: [
                    {
                        text: '今天',
                        value() {
                            return new Date();
                        }
                    },
                    {
                        text: '昨天',
                        value() {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return date;
                        }
                    },
                    {
                        text: '一周前',
                        value() {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            return date;
                        }
                    }
                ]
            },
            payTime: {
                shortcuts: [
                    {
                        text: '最近一周',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近一个月',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近三个月',
                        value() {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            return [start, end];
                        }
                    }
                ]
            },
            batchList: [],//批次
        }
    },
    mounted() {
        this.getBatchOptions();
    },
    methods: {
        getBatchOptions() {
            this.$manageHttp.getBatchOptions().then(data => {
                if (data.code == '200') {
                    this.batchList = data.data;
                    this.formInline.batchId=this.batchList[0].id;
                    this.getData();
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 表单清空
        resetForm(fileName) {
            this.$refs[fileName].resetFields();
        },
        onSearch() {
            this.currentPage = 1;
            this.getData();
        },
        // 页码改变的回调，返回改变后的页码(当前页)
        sizeChange(val) {
            this.currentPage = val;
            this.getData();
        },
        // 切换每页条数时的回调，返回切换后的每页条数(每页数)
        pageChange(val) {
            this.limit = val;
            this.getData();
        },
        planChange(val) {
            console.log(val, 'val');
            this.formInline.submitDate = val;
        },
        queryChange(val) {
            this.queryArray[0] = val[0];
            this.queryArray[1] = val[1];
        },
        getData() {
            this.loading = true;
            var params = {
                pageNum: this.currentPage,
                pageSize: this.limit,
                orderByColumn: 'a.examPlanNo',
                isAsc: 'desc',
                examPlanNo: this.formInline.examPlanNo,
                examMajorTitle: this.formInline.examMajorTitle,
                examRoom: this.formInline.examRoom,
                examAddress: this.formInline.examAddress,
                submit: this.formInline.submit,
                submitDate: this.formInline.submitDate,
                examBeginTime: this.queryArray[0],
                examEndTime: this.queryArray[1],
                batchId:this.formInline.batchId
            }
            this.$manageHttp.examPlan(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.tabData = data.rows;
                    this.total = data.total;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        //导出考试计划
        exportList() {
            this.loading = true;
            var params = {
                pageNum: 1,
                pageSize: 10000,
                orderByColumn: 'a.examPlanNo',
                isAsc: 'desc',
                examPlanNo: this.formInline.examPlanNo,
                examMajorTitle: this.formInline.examMajorTitle,
                examRoom: this.formInline.examRoom,
                examAddress: this.formInline.examAddress,
                submit: this.formInline.submit,
                submitDate: this.formInline.submitDate,
                examBeginTime: this.queryArray[0],
                examEndTime: this.queryArray[1],
                batchId:this.formInline.batchId
            }
            this.$manageHttp.exportExamPlanList(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.$manageHttp.download(data.msg);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 选中某一项触发
        selectChange(selection, row) {
            console.log(selection, 'selection')
            this.selections = selection;
        },
        // 一键发布考试计划
        publishBatch() {
            this.$Modal.confirm({
                title: "确定要一键发布考试计划吗?",
                onOk: () => {
                    this.spinShow = true;
                    this.loadText = '一键发布考试计划中...';
                    this.$manageHttp.submitAll().then(data => {
                        if (data.code == '200') {
                            this.spinShow = false;
                            this.$Message.success("一键发布考试计划成功!");
                            this.getData();
                        } else {
                            this.$Message.error(data.msg);
                            this.spinShow = false;
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                },
                onCancel: () => {
                    this.$Message.warning("已取消操作!");
                }
                // cancelText: "取消",
            });
        },
        // 一键生成准考证
        createBatch() {
            this.$Modal.confirm({
                title: "确定要一键生成准考证吗?",
                onOk: () => {
                    this.spinShow = true;
                    this.loadText = '一键生成准考证中...';
                    this.$manageHttp.genExamTicketNoAll().then(data => {
                        if (data.code == '200') {
                            this.spinShow = false;
                            this.$Message.success("一键生成准考证成功!");
                            this.getData();
                        } else {
                            this.$Message.error(data.msg);
                            this.spinShow = false;
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                },
                onCancel: () => {
                    this.$Message.warning("已取消操作!");
                }
                // cancelText: "取消",
            });
        },
        /* createBatch() {
          if (this.selections.length) {
            this.batchGenExamPlanNo = [];
            this.batchMode = true;

            this.selections.forEach(item => {
              if (item.submit == 1) {
                var msg = '考试计划' + item.examPlanNo + '已生成准考证,跳过';
                this.batchGenExamPlanNo.push(msg);
                console.log(msg);
                return false;
              }
              this.$manageHttp.cardSubmit({
                id: item.id
              }).then(data => {
                if (data.code == '200') {
                  var msg = '考试计划' + item.examPlanNo + '生成准考证,成功';
                  this.batchGenExamPlanNo.push(msg);
                  console.log(msg);
                } else {
                  this.$Message.error(data.msg);
                }
              }).catch(error => {
                var msg = '考试计划' + item.examPlanNo + '生成准考证,出错,错误原因' + error;
                this.batchGenExamPlanNo.push(msg);
              })
            })
            var msg = '准考证生成完毕';
            // this.print(this.chartId);

          } else {
            this.$Message.warning('请先选择要生成准考证的数据!');
            this.batchGenExamPlanNo.push(msg);
          }
        }, */
        // 发布
        publish(row) {
            this.$Modal.confirm({
                title: "确定要发布考试计划吗?",
                onOk: () => {
                    this.spinShow = true;
                    this.loadText = '考试计划发布中...';
                    this.$manageHttp.publish({
                        id: row.id
                    }).then(data => {
                        if (data.code == '200') {
                            this.spinShow = false;
                            this.$Message.success("考试计划已发布成功!");
                            this.getData();
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                },
                onCancel: () => {
                    this.$Message.warning("已取消操作!");
                }
                // cancelText: "取消",
            });
        },
        // 生成准考证
        createCard(row) {
            this.$Modal.confirm({
                title: "确定要生成准考证吗?",
                onOk: () => {
                    this.spinShow = true;
                    this.loadText = '准考证生成中...';
                    this.$manageHttp.cardSubmit({
                        id: row.id
                    }).then(data => {
                        if (data.code == '200') {
                            this.spinShow = false;
                            this.$Message.success("准考证号已生成成功!");
                            this.getData();
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                },
                onCancel: () => {
                    this.$Message.warning("已取消操作!");
                }
                // cancelText: "取消",
            });
        },
        // 考场考生人数
        students(row) {
            this.studentModal = true;
            this.checkLoading = true;
            var params = {
                examPlanId: row.id
            }
            this.examPlanRegistrationList(params);
        },
        // 获取考试计划人员明细
        examPlanRegistrationList(params) {
            this.$manageHttp.examPlanRegistrationList(params).then(data => {
                this.checkLoading = false;
                if (data.code == '200') {
                    this.studentData = data.rows;
                    this.hasAllotData = data.rows;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        autoClick() {
            this.autoModal = true;
            this.noExamPlanCount();
        },
        examChange(val) {
            console.log(val, 'val');
            this.autoForm.examValue = val;
        },
        startChange(val) {
            console.log(val, 'val');
            this.handForm.examValue = val;
        },
        subjectChangeForAutoPlan() {
            this.noExamPlanCount(this.autoForm.examMajorTitle,this.autoForm.examMajor);
        },
        // 自动分配考生---确定
        autoSure(name) {
            var params = {
                examMajorTitle: this.autoForm.examMajorTitle,
                examMajor: this.autoForm.examMajor,
                examBeginTime: this.autoForm.examValue[0],
                examEndTime: this.autoForm.examValue[1],
                examRoom: this.autoForm.examRoom,
                examAddress: this.autoForm.examAddress,
                examPersonCount: this.autoForm.examPersonCount,
            }
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.sureLoading = true;
                    this.$manageHttp.autoMakeExamPlan(params).then(data => {
                        if (data.code == '200') {
                            this.$Message.success('自动分配考生成功!');
                            setTimeout(() => {
                                this.sureLoading = false;
                                this.autoModal = false;
                                this.getData();
                            }, 1500)
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                } else {
                    return false;
                }
            })
        },
        handClick(val) {
            if (val == true) {
                this.resetForm('handForm');
                if (this.editType == 'add') {
                    this.resetForm('handForm');
                    this.handSave = false;
                } else {
                    var that = this;

                    this.$manageHttp.getExamById(this.id).then(data => {
                        if (data.code == '200') {
                            var data = data.data;
                            that.handForm.examValue[0] = data.examBeginTime;
                            that.handForm.examValue[1] = data.examEndTime;
                            that.handForm.examMajorTitle = data.examMajorTitle;
                            that.handForm.examRoom = data.examRoom;
                            that.handForm.examAddress = data.examAddress;

                            this.noExamPlanlist();
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {

                    })
                }
                // this.noExamPlanCount();
                // this.noExamPlanlist();
                var params = {
                    examPlanId: this.id
                }
                this.examPlanRegistrationList(params);
            } else {
                if (this.handSave == false && this.editType == 'add') {
                    var params = {
                        "id": this.id
                    };
                    this.$manageHttp.deleteById(params).then(data => {
                        if (data.code == '200') {
                            // this.getData();
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {

                    })
                } else {
                    this.getData();
                }
            }
        },
        subjectChange(val) {
            if (val) {
                this.noExamPlanlist();
            } else {
                this.allotData = [];
            }
        },
        // 手动分配考生
        handBtn() {
            this.editType = 'add';
            this.addExamPlan();
        },
        // 新增考试计划
        addExamPlan() {
            var that = this;
            this.$manageHttp.addExamPlan({}).then(data => {
                if (data.code == '200') {
                    that.id = data.data;
                    this.handModal = true;
                    this.handTitle = '手动分配考生';
                    this.condition = '';
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 页码改变的回调，返回改变后的页码(当前页)
        planSizeChange(val) {
            this.plan.currentPage = val;
            this.noExamPlanlist();
            // this.getData();
        },
        // 切换每页条数时的回调，返回切换后的每页条数(每页数)
        planPageChange(val) {
            this.plan.limit = val;
            this.noExamPlanlist();
        },
        // 获取待安排考试的报名列表
        noExamPlanlist() {
            this.loading = true;
            var params = {
                pageNum: this.plan.currentPage,
                pageSize: this.plan.limit,
                examMajorTitle: this.handForm.examMajorTitle,
                condition: this.condition
            }
            this.$manageHttp.noExamPlanlist(params).then(data => {
                if (data.code == '200') {
                    this.loading = false;
                    this.allotData = data.rows;
                    this.plan.total = data.total;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 搜索
        allSearch(value) {
            this.noExamPlanlist();
        },
        // 待分配考生(添加)
        addExam(row, index) {
            debugger;
            if (this.loadings === true) {
                console.log("重复");
                return;
            }
            this.loadings = true;
            this.hasAllotLoading = true;
            var seatNum = 1;
            if (this.hasAllotData.length > 0) {
                var seatNo = this.hasAllotData[this.hasAllotData.length - 1].seatNo;
                seatNo = parseInt(seatNo) + 1;
                seatNum = seatNo;
            }
            seatNum += '';
            var params = {
                examPlanId: this.id,
                regId: row.id,
                seatNo: seatNum.length < 2 ? '0' + seatNum : seatNum
            }
            var examPlanId = {
                examPlanId: this.id
            }
            var that = this;
            this.$manageHttp.relExamplanRegistration(params).then(data => {
                this.hasAllotLoading = false;
                if (data.code == '200') {
                    that.loadings = false;
                    this.examPlanRegistrationList(examPlanId);
                    this.allotData.splice(index, 1);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 替换考生
        replace(row) {
            console.log(row, 'row');
            this.oldId = row.relExamPlanRegId;
            this.replaceMode = true;
            var params = {
                submit: 0,
                examMajorTitle: this.handForm.examMajorTitle
            }
            this.$manageHttp.examPlan(params).then(data => {
                if (data.code == '200') {
                    this.planData = data.rows;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 单击选中
        currentChange(row) {
            this.replaceLoading = true;
            var params = {
                examPlanId: row.id
            }
            this.$manageHttp.examPlanRegistrationList(params).then(data => {
                this.replaceLoading = false;
                if (data.code == '200') {
                    this.replaceData = data.rows;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 确认替换
        sureReplace(row) {
            var params = {
                oldId: this.oldId,
                newId: row.relExamPlanRegId
            }
            var examPlanId = {
                examPlanId: this.id
            }
            if (params.oldId == params.newId) {
                this.$Message.error("相同的考生不能替换,请重新选择!");
            }
            this.$manageHttp.replace(params).then(data => {
                if (data.code == '200') {
                    this.replaceMode = false;
                    this.$Message.success("当前考生替换成功!");
                    this.examPlanRegistrationList(examPlanId);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        replaceChange(flag) {
            if (flag) {
                this.replaceData = [];
            }
        },
        // 已分配座位号---修改
        seatEdit(row, index) {
            this.editSeat = row.seatNo;
            this.seatIndex = index;
        },
        // 已分配座位号---保存
        seatSave(row, index) {
            var small = this.editSeat + '';
            if (small.length < 2) {
                small = '0' + small;
            }
            var params = {
                id: row.relExamPlanRegId,
                examPlanId: this.id,
                regId: row.regId,
                seatNo: small
            }
            var examPlanId = {
                examPlanId: this.id
            }
            this.$manageHttp.updateSeat(params).then(data => {
                if (data.code == '200') {
                    this.seatIndex = -1;
                    this.examPlanRegistrationList(examPlanId);
                    // this.hasAllotData[index].seatNo = this.editSeat;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {

            })

        },
        // 已分配(删除)
        hasDelete(row, index) {
            console.log(index, 'index');
            this.hasAllotLoading = true;
            this.$manageHttp.deleteRelExamById(row.relExamPlanRegId).then(data => {
                this.hasAllotLoading = false;
                if (data.code == '200') {
                    this.noExamPlanlist();
                    this.studentData.splice(index, 1);
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })

        },
        // 手动分配考生---确定
        handSure(name) {
            var params = {
                id: this.id,
                examBeginTime: this.handForm.examValue[0],
                examEndTime: this.handForm.examValue[1],
                examMajorTitle: this.handForm.examMajorTitle,
                // examBeginTime: this.examValue[0],
                // examEndTime: this.examValue[1],
                examRoom: this.handForm.examRoom,
                examAddress: this.handForm.examAddress,
                examPersonCount: this.studentData.length
            }
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$manageHttp.saveExamPlan(params).then(data => {
                        if (data.code == '200') {
                            this.handSave = true;
                            this.$Message.success('手动分配考生成功!');
                            setTimeout(() => {
                                this.handModal = false;
                                this.getData();
                            }, 500)
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {

                    })
                    // this.handModal = false;
                    // this.getData();
                } else {
                    return false;
                }
            })
        },
        // 修改
        updateBtn(row) {
            this.id = row.id;
            this.genExamTicketNo = row.genExamTicketNo;
            this.handModal = true;
            this.handTitle = '修改考试计划';
            // this.noExamPlanCount();
            this.editType = 'edit';
        },
        // 获取待安排考生人数
        noExamPlanCount(examMajorTitle,examMajor) {
            var params = {
                examMajorTitle: examMajorTitle,
                examMajor: examMajor
            }
            this.$manageHttp.noExamPlanCount(params).then(data => {
                if (data.code == '200') {
                    this.planNum = data.data;
                } else {
                    this.$Message.error(data.msg);
                }
            }).catch(error => {
                this.$Message.error(error);
            })
        },
        // 删除
        deleteBtn(row) {
            this.$Modal.confirm({
                title: "确定要删除吗?",
                onOk: () => {
                    this.$manageHttp.deleteById({
                        id: row.id
                    }).then(data => {
                        if (data.code == '200') {
                            this.$Message.success(data.msg);
                            setTimeout(() => {
                                this.getData();
                            }, 1500)
                        } else {
                            this.$Message.error(data.msg);
                        }
                    }).catch(error => {
                        this.$Message.error(error);
                    })
                },
                onCancel: () => {
                    this.$Message.warning("已取消操作!");
                }
                // cancelText: "取消",
            });

        },
    }
}