<template>
  <div class="header">
    <div class="logo fl">
      <img src="../assets/images/logo.png" alt />
      <!-- <h3 class="title">水利工程能力测试</h3> -->
    </div>
    <Menu class="fl" mode="horizontal" :theme="theme" active-name="1">
      <!-- <MenuItem :name="item.name" :to="item.to" v-for="(item,i) in headList" :key="i">
      <Icon :custom="item.icon" :size="item.size" />{{item.label}}
      </MenuItem> -->
      <MenuItem name="1" to="/audit">
      <Icon custom="iconfont icon-baoming" size="20" />审核报名
      </MenuItem>
      <MenuItem name="2" to="/adminRecord">
      <Icon custom="iconfont icon-shenhe" size="20" />审核记录
      </MenuItem>
      <MenuItem name="3" to="/adminPay">
      <Icon custom="iconfont icon-jiaofeishezhi" size="20" />报名缴费
      </MenuItem>
      <MenuItem name="4" to="/adminPlan">
      <Icon custom="iconfont icon-kaoshijihua" size="20" />制定考试计划
      </MenuItem>
      <MenuItem name="5" to="/adminEnter">
      <Icon custom="iconfont icon-chengji" size="20" />成绩录入
      </MenuItem>
      <MenuItem name="6" to="/adminPerformance">
      <Icon custom="iconfont icon-chaxunyewu" size="20" />成绩查询
      </MenuItem>
      <MenuItem name="7" to="/adminAccount" v-show="isRoles">
      <Icon custom="iconfont icon-zhanghuguanli" size="20" />账户管理
      </MenuItem>
    </Menu>
    <Menu class="fr" mode="horizontal" :theme="theme" active-name="2" @on-select='selectChange'>
      <Submenu name="6">
        <template slot="title" class="demo-avatar">
          <Avatar icon="ios-person" />
          <span class="name">{{userInfo.nickName}}</span>
        </template>
        <MenuGroup>
          <MenuItem name="update">修改密码</MenuItem>
        </MenuGroup>
        <MenuGroup>
          <MenuItem name="logout">退出登录</MenuItem>
        </MenuGroup>
      </Submenu>
    </Menu>
    <!-- 修改密码 -->
    <Modal title="修改密码" v-model="updateModal" footer-hide width="30%">
      <Form class="addUser" ref="updateForm" :model="updateForm" :rules='updateRule' :label-width="120" label-colon>
        <FormItem label="旧密码" prop="oldPassword">
          <Input type="password" v-model="updateForm.oldPassword" placeholder="旧密码" clearable></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <Input type="password" v-model="updateForm.newPassword" placeholder="新密码" clearable></Input>
        </FormItem>
        <FormItem label="确认新密码" prop='surePassword'>
          <Input type="password" v-model="updateForm.surePassword" placeholder="确认新密码" clearable></Input>
        </FormItem>
        <FormItem class="bot">
          <Button type="primary" @click="updatePwd('updateForm')">确定</Button>
          <Button type="primary" ghost @click="updateModal = false">取消</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    var userPassWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.updateForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      theme: "primary",
      userInfo: {},
      updateModal: false,
      isRoles: false,
      updateForm: {
        oldPassword: "",
        newPassword: "",
        surePassword: "",
      },
      updateRule: {
        oldPassword: [
          { required: true, message: "旧密码为必填", trigger: "change" },
        ],
        newPassword: [
          { required: true, message: "新密码为必填", trigger: "change" },
          {
            pattern: /^[a-zA-Z0-9]{6,15}$/,
            message: "密码长度不能小于6位的字母或数字",
            trigger: "blur",
          },
        ],
        surePassword: [
          { required: true, message: "确认密码为必填", trigger: "change" },
          { required: true, validator: userPassWord, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.rolesFlag();
  },
  methods: {
    rolesFlag() {
      for (var i = 0; i < this.userInfo.roles.length; i++) {
        var item = this.userInfo.roles[i];
        if (item.roleKey.toLowerCase() == "zgbadmin") {
          this.isRoles = true;
        } else {
          this.isRoles = false;
        }
      }
    },
    selectChange(name) {
      if (name == "logout") {
        this.logout();
      } else {
        this.updateModal = true;
        this.$refs["updateForm"].resetFields();
      }
    },
    logout() {
      this.$Modal.confirm({
        title: "确定要退出登录吗?",
        onOk: () => {
          this.$manageHttp
            .logout()
            .then((data) => {
              /* if (data.code == "200") {
                // sessionStorage.removeItem("manageToken");
              } else {
                // this.$Message.error(data.msg);
              } */
              console.log(data.msg);
              sessionStorage.removeItem("manageToken");
              this.$Message.success("退出成功!");
              setTimeout(() => {
                this.$router.push({
                  path: "/login",
                });
              }, 500);
            })
            .catch((error) => {});
        },
        cancelText: "取消",
      });
    },
    updatePwd(name) {
      var params = {
        oldPassword: this.updateForm.oldPassword,
        newPassword: this.updateForm.newPassword,
      };
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$manageHttp
            .updatePwd(params)
            .then((data) => {
              if (data.code == "200") {
                this.$Message.success("密码修改成功!");
                setTimeout(() => {
                  this.$router.push({
                    path: "/login",
                  });
                }, 500);
              } else {
                this.$Message.error(data.msg);
              }
            })
            .catch((error) => {});
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/icon_custom/iconfont.css");

/deep/.ivu-menu-item-group-title {
  display: none;
}
/deep/.ivu-menu-item {
  font-size: 16px;
}
/deep/.ivu-menu-submenu-title span > i,
.ivu-menu-submenu-title > i {
  margin-right: 0;
}
.header {
  width: 100%;
  height: 60px;
  background: #2d8cf0;
  padding: 0 20px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
}
.logo {
  width: 230px;
  height: 100%;
  position: relative;
  img {
    max-width: 90%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.name {
  margin-left: 10px;
  margin-right: 5px;
}
.title {
  font-size: 24px;
  color: white;
  line-height: 60px;
}
</style>